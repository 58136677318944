<template>
  <div
    v-if="show"
    class="modal modal__right"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    id="addModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="modal__close modal__close--white d-flex align-items-center justify-content-between"
                @click="confirmSave()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              For Users Change Password
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <label class="table__body__text table__text mb-3">
                After changing your password the system will automatically log
                you out so you can log in with your new password.
              </label>
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                  @click="confirmSave()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                  @click="save()"
                >
                  {{ process ? "Saving..." : "Save" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--EDIT USER MODAL-->
    <div class="modal__dialog" role="document">
      <div class="modal__content">
        <div class="modal__head">
          <div class="modal__head__icon">
            <a
              data-dismiss="#addModal"
              class="modal__close modal__close--black"
              @click="closeModal"
            >
              <ion-icon name="arrow-back-outline"></ion-icon>
            </a>
          </div>
          <h5 class="modal__head__title text--capital">change password</h5>
          <label v-if="user.enabled2FA" class="form__label">
            Open your google authenticator app and input your generated token
            below.
          </label>
        </div>
        <div class="modal__body">
          <form>
            <div class="row">
              <div class="col-12" v-if="user.enabled2FA">
                <div class="form__item">
                  <label class="form__label">Token</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    placeholder="Enter google generated token"
                    id="token"
                    ref="token"
                    :autofocus="user.enabled2FA"
                    required
                    v-model="token"
                    @keyup="mountAutoFocus('oldPassword1')"
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="form__item">
                  <label class="form__label">Old password</label>
                  <input
                    type="password"
                    class="input form__input form__input--lg"
                    placeholder="Enter old password"
                    id="oldPassword1"
                    ref="oldPassword1"
                    autofocus
                    required
                    v-model="oldPassword"
                    @keyup="mountAutoFocus($event, 'newPassword')"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form__item">
                  <label class="form__label">New Password</label>
                  <input
                    type="password"
                    class="input form__input form__input--lg"
                    placeholder="Enter new password"
                    id="newPassword"
                    ref="newPassword"
                    autofocus
                    required
                    v-model="newPassword"
                    @keyup="mountAutoFocus($event, 'confirmPassword')"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form__item">
                  <label class="form__label">Confirm New Password</label>
                  <input
                    type="password"
                    class="input form__input form__input--lg"
                    placeholder="Confirm new password"
                    id="confirmPassword"
                    ref="confirmPassword"
                    autofocus
                    required
                    v-model="confirmPassword"
                    @input="checkPasswordMatch"
                    :style="
                      confirmPasswordErr
                        ? 'border: 2px solid red !important'
                        : ''
                    "
                  />
                </div>
              </div>
            </div>
            <div class="form__item">
              <div class="form__action">
                <button
                  data-dismiss="#addModal"
                  type="button"
                  class="button form__button form__button--lg w-30 save-changes"
                  @click="confirmSave()"
                >
                  {{ process ? "Saving..." : "save changes" }}
                </button>
                <a
                  data-dismiss="#addModal"
                  role="button"
                  class="modal__close form__action__text form__action__text--danger text--capital ml6"
                  @click="closeModal"
                >
                  cancel
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
function initialState() {
  return {
    token: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    confirmPasswordErr: false,
    confirm: false,
    process: false,
    isCustomize: false,
  };
}
// import { mapState } from "vuex";
import {
  SET_TWO_FACTOR_AUTH,
  CHANGE_PASSWORD,
} from "@/core/services/store/types";
export default {
  name: "ChangeUserPassword",
  props: {
    show: Boolean,
    close: Function,
    user: Object,
  },
  data: function () {
    return initialState();
  },
  methods: {
    resetWindow: function () {
      Object.assign(this.$data, initialState());
    },
    confirmSave() {
      if (!this.confirm) {
        this.confirm = true;
      } else {
        this.confirm = false;
      }
    },
    validate() {
      if (this.user.enabled2FA && this.token == "") {
        this.$emit("alertWarning", "You must provide a token!");
        return false;
      }
      if (this.oldPassword == "") {
        this.$emit("alertWarning", "You must provide your old password!");
        return false;
      }
      if (this.newPassword == "") {
        this.$emit("alertWarning", "You must provide a new password!");
        return false;
      }
      if (this.newPassword !== this.confirmPassword) {
        this.$emit("alertWarning", "Your password do not match!");
        this.confirmPasswordErr = true;
        return false;
      }

      this.confirmPasswordErr = false;
      return true;
    },
    closeModal() {
      this.resetWindow();
      this.$emit("close", false);
    },
    checkPasswordMatch(e) {
      const { value } = e.target;

      if (value !== this.newPassword) return (this.confirmPasswordErr = true);

      return (this.confirmPasswordErr = false);
    },
    save: function () {
      if (!this.validate()) return;
      this.process = true;

      if (this.user.enabled2FA) {
        const payload = {
          secretCode: this.user.user2faSecretKey,
          userCode: this.token,
        };
        this.$store
          .dispatch(SET_TWO_FACTOR_AUTH, payload)
          // go to which page after successfully login
          .then(() => {
            // this.process = false;
            this.change();
          })
          .catch((e) => {
            this.process = false;
            this.$emit(
              "alertError",
              `${e.data.message} verification failed. Please try again`
            );
          });
      } else {
        this.change();
      }
    },

    mountAutoFocus(e, refKey) {
      if (e.key === "Enter") this.$refs[refKey].focus();
    },

    change() {
      this.$store
        .dispatch(CHANGE_PASSWORD, {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        })
        .then(() => {
          this.confirm = false;
          this.process = false;
          this.$emit(
            "alertSuccess",
            "Your password is successfully changed, the system will log you out now."
          );
          setTimeout(() => {
            this.$emit("close", true);
          }, 1000);
        })
        .catch((e) => {
          this.process = false;
          this.$emit("alertError", e.data.message);
        });
    },
  },

  mounted() {
    this.$watch("show", function (value) {
      if (value)
        this.user.enabled2FA
          ? this.$refs.token.focus()
          : this.$refs.oldPassword1.focus();
    });
  },
};
</script>
